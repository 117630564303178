
import { Component, Vue } from "vue-property-decorator";
import LogoSingle from "@/components/global/LogoSingle.vue";
import StepTracker from "@/components/request/StepTracker.vue";
import SearchService from "@/components/request/SearchService.vue";
import SearchServiceNew from "@/components/request/SearchServiceNew.vue";
import DialogService from "@/components/request/DialogService.vue";

@Component({
  components: {
    LogoSingle,
    StepTracker,
    SearchService,
    SearchServiceNew,
    DialogService,
    IdentifyUser: () => import("@/components/request/IdentifyUser.vue"),
    SendRequest: () => import("@/components/request/SendRequest.vue"),
    EndRequest: () => import("@/components/request/EndRequest.vue")
  }
})
export default class NewRequest extends Vue {
  private base_bucket = process.env.VUE_APP_AWS_ASSETS;
  private service: any = {};
  private mounted() {
    if (localStorage.getItem("step_state")) {
      localStorage.removeItem("step_state");
    } else {
      this.$store.dispatch("setStateToCero");
      this.$store.dispatch("cleanTicket");
    }
    window.scroll(0, 0);
  }

  private selectService(e: any) {
    if (e) {
      this.service = e;
    }
  }
}
